/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import Avatar from 'react-avatar';
import prevButton from '../images/carousel-button-left.png';
import nextButton from '../images/carousel-button-right.png';
import device from '../Styles/Device';
import { shopData } from '../Data/data';

const CustomPrevArrow = props => {
  const { className, style, onClick } = props;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={prevButton} alt="arrow_left" />
    </div>
  );
};

const CustomNextArrow = props => {
  const { className, style, onClick } = props;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={nextButton} alt="arrow_right" />
    </div>
  );
};

const Wrapper = styled.div`
  width: 100%;
  /* max-width: ${props => props.theme.maxWidth}; */
  background-color: white;
  margin: 0 auto;
`;
const Container = styled.div`
  width: 80%;
  margin: 0 auto 0 auto;
  padding: 80px 0 50px 0;
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
`;
const BoxItem = styled.div`
  text-align: center;
  h3 {
    margin-top: 10px;
    font-size: 1em;
    font-weight: 400;
    color: ${props => props.theme.textBlackColor};
  }
  h4 {
    font-size: 1em;
    font-weight: 400;
    color: ${props => props.theme.textBlueColor};
  }
`;

const TextBox = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 80%;
  margin-top: 20px;
  span {
    font-size: 1.1em;
    color: ${props => props.theme.textBlueColor};
    font-weight: 300;
  }
  @media ${device.desktop} {
    width: 60%;
  }
  @media ${device.laptop} {
    width: 70%;
  }
`;
const StyledSlider = styled(Slider)`
  .slick-next,
  .slick-prev {
    width: 3rem;
    height: 3rem;
    top: 40%;
  }
`;

export default () => {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };
  return (
    <Wrapper>
      <Container>
        <StyledSlider {...settings}>
          {shopData.map((data, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={index}>
                <BoxItem>
                  <Avatar src={data.avatarImg} size="80" round />
                  <h3>{data.name}</h3>
                  <h4>{data.shopName}</h4>
                  <TextBox>
                    {data.text.split('\n').map(line => {
                      return (
                        <span>
                          {line}
                          <br />
                        </span>
                      );
                    })}
                  </TextBox>
                </BoxItem>
              </Box>
            );
          })}
        </StyledSlider>
      </Container>
    </Wrapper>
  );
};
