import React from 'react';
import styled, { css } from 'styled-components';
import device from '../Styles/Device';

const StyledFormItem = styled.input`
  width: 100%;
  padding: 10px 7px;
  font-size: 14px;
  vertical-align: center;
  outline: none;
  border: none;
  background-color: transparent;

  font-weight: 400;
  color: #1a1a1a;
  ${props =>
    props.value !== ''
      ? css`
          border-bottom: 2px solid #1a1a1a;
        `
      : css`
          border-bottom: 1px solid #e6e7e8;
        `}
  &::-webkit-input-placeholder {
    color: #bfc0c1;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bfc0c1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bfc0c1;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #bfc0c1;
  }

  & + & {
    margin-top: 15px;
  }

  @media ${device.desktop} {
    padding: 16px 14px;
    font-size: 20px;
    & + & {
      margin-top: 30px;
    }
  }
`;

export default function FormItem({ children, ...rest }) {
  return (
    <>
      {/* eslint-disable-next-line */}
      <StyledFormItem {...rest} />
    </>
  );
}
