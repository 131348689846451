import React from 'react';
import { Switch, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyles from '../Styles/GlobalStyles';
import Theme from '../Styles/Theme';
import Main from './Main';
import SignIn from './SignIn';

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
`;

const App = () => {
  return (
    <ThemeProvider theme={Theme}>
      <>
        <GlobalStyles />
        <Wrapper>
          <Switch>
            <Route path="/" component={Main} exact />
            <Route path="/signin" component={SignIn} />
          </Switch>
        </Wrapper>
      </>
    </ThemeProvider>
  );
};

export default App;
