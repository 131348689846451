import React from 'react';
import styled from 'styled-components';
import { Column2Img } from '../Images';
import device from '../../Styles/Device';
import androidIconImg from '../../images/androidstore.png';
import appleIconImg from '../../images/apple-white-icon.png';
import { DownloadIcon } from '../Icon';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const Container = styled.div`
  margin-top: 100px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: ${props => props.theme.maxWidth};
  width: 100%;
  margin-left: 10%;
  margin-right: 10%;
  @media ${device.desktop} {
    flex-direction: row;
  }
`;

const Column = styled.div`
  flex: 1 1 auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  &:first-child {
    align-items: flex-start;
  }
  &:last-child {
    align-items: flex-end;
  }
  @media ${device.desktop} {
    flex-direction: row;
    &:first-child {
      flex-direction: column;
    }
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0%;
  margin-right: 0%;
  @media ${device.laptop} {
    margin-left: 0%;
  }
  @media ${device.tablet} {
    margin-left: 0%;
  }
  @media ${device.phone} {
    align-items: flex-start;
    margin-left: 5%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const ColumnText = styled.p`
  &:first-child {
    color: ${props => props.theme.blackColor};
    margin-top: 40px;
    margin-bottom: 10px;
    @media ${device.phone} {
      margin-top: 0px;
    }
  }
  color: ${props => props.theme.textBlackColor};
  margin-top: 10px;
  font-size: 2.4em;
  font-weight: 400;
  line-height: 1.3;
  &:last-child {
    margin-bottom: 50px;
  }
  @media ${device.phone} {
    font-size: 1.5em;
  }
  @media ${device.desktop} {
    width: 480px;
  }
`;
const ColumnText1 = styled.span`
  &:first-child {
    font-weight: 400;
    margin-top: 40px;
    @media ${device.phone} {
      margin-top: 0px;
    }
  }
  color: ${props => props.theme.textBlackColor};
  font-size: 2em;
  font-weight: 400;
  line-height: 1.3;
  @media ${device.phone} {
    font-size: 1.5em;
  }
  @media ${device.desktop} {
    width: 480px;
  }
`;
const ColumnText2 = styled.span`
  &:first-child {
    color: #642A8C;
    font-size: 1em;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 10px;
    @media ${device.phone} {
      margin-top: 0px;
    }
  }
  color: ${props => props.theme.textBlackColor};
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.3;
  @media ${device.phone} {
    font-size: 1.5em;
  }
  @media ${device.desktop} {
    width: 480px;
  }
`;

const Button = styled.div`
  color: white;
  font-weight: 300;
  background: black;
  width: 10em;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border-radius: 4px;
  cursor: pointer;
  margin: 30px 20px 0 0;
  @media ${device.phone} {
    margin: 20px 4px;
    height: 3.5em;
  }
`;
export default () => {
  return (
    <Wrapper>
      <Container>
        <Column>
          <ColumnContainer>
            <ColumnText1>출퇴근관리부터 급여계산,</ColumnText1>
            <ColumnText1>업무 체크리스트까지</ColumnText1>
            <ColumnText>직원관리의 필수템, <ColumnText2>퇴근해CU!</ColumnText2></ColumnText>
            <ButtonContainer>
              <a href="http://bit.ly/3avT8mO">
                <Button>
                  <DownloadIcon src={androidIconImg} />
                  <div>Google Play</div>
                </Button>
              </a>
              <a href="https://apple.co/3biWx8T">
                <Button>
                  <DownloadIcon src={appleIconImg} />
                  <div>App Store</div>
                </Button>
              </a>
            </ButtonContainer>
          </ColumnContainer>
        </Column>
        <Column>
          <Column2Img />
        </Column>
      </Container>
    </Wrapper>
  );
};
