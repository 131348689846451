import React from 'react';
import styled from 'styled-components';
import device from '../../Styles/Device';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.darkGreyColor};
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  @media ${device.desktop} {
    padding: 120px 0;
  }
`;

const Container = styled.div`
  display: flex;
  max-width: ${props => props.theme.maxWidth};
  width: 100%;
  margin-left: 10%;
  margin-right: 0%;

  @media ${device.desktop} {
    & {
      margin-right: 10%;
    }
  }
`;

const Column = styled.div`
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:last-child {
    justify-content: center;
  }
  @media ${device.desktop} {
    &:last-child {
      align-items: flex-start;
    }
  }
`;
const TextContainer = styled.div`
  text-align: justify;
  text-align-last: justify;
  word-wrap: nowrap;
`;

const H1 = styled.h1`
  color: ${props => props.theme.textWhiteColor};
  font-weight: 400;
  font-size: 2.4em;
  @media ${device.phone} {
    font-size: 1.9em;
  }
  &:first-child {
    letter-spacing: 0.13em;
  }
  &:nth-child(2) {
    letter-spacing: 0.45em;
  }
  &:nth-child(3) {
    letter-spacing: 0.1em;
  }
`;

const Border = styled.div`
  width: 100%;
  border: 0.5px solid #7a7b80;
`;

export default () => {
  return (
    <Wrapper>
      <Container>
        <Column>
          <TextContainer>
            <H1>직원관리의</H1>
            <H1>핵 심 만</H1>
            <H1>담았습니다.</H1>
          </TextContainer>
        </Column>
        <Column>
          <Border />
        </Column>
      </Container>
    </Wrapper>
  );
};
