import React from 'react';
import styled from 'styled-components';
import device from '../Styles/Device';
import naverBlogIcon from '../images/naver_blog_icon.svg';
import youtubeIcon from '../images/youtube_icon.svg';
import facebookIcon from '../images/facebook-icon.svg';

const Wrapper = styled.div`
  background-color: ${props => props.theme.footerBgColor};
  min-height: 300px;
  margin-top: -6px;
`;
const Container = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
`;
const Column = styled.div`
  margin-left: 10%;
  margin-right: 30px;
  &:first-child {
    padding-top: 50px;
  }
  text-align: left;

  &:last-child {
    margin-top: 20px;
    text-align: right;
    @media ${device.desktop} {
      margin-top: 0;
      text-align: left;
    }
  }
`;

const IconContainer = styled.div`
  text-align: right;
  margin-top: 50px;
  & a {
    display: inline-block;
    width: 35px;
    cursor: pointer;
  }
  & a + a {
    margin-left: 15px;
  }
`;

const H3 = styled.h4`
  color: ${props => props.theme.footerTextColor};
  font-weight: 300;
`;
const Link = styled.a`
  color: ${props => props.theme.footerTextColor};
  font-weight: 400;
`;
export default () => {
  return (
    <Wrapper>
      <Container>
        <Column>
          <H3>(주) 위솝 | 대표 : 김규한</H3>
          <H3>
            사업자 등록번호 : 703-86-01169 | 서울특별시 마포구 백범로 35 서강대학교 아루페관(스마일게이트 오렌지팜) 302호
          </H3>
          <H3>
            이메일 문의 :{' '}
            <Link href="mailto:help@wesop.co.kr">help@wesop.co.kr</Link> |
            전화 문의 : <Link href="tel:070-8633-1119">070-8633-0762</Link>
          </H3>
         
        </Column>
        <Column>


        
        {/* <a href="http://pf.kakao.com/_tAUxlj" target="_blank">
              <img
                className="social-button"
                src="https://developers.kakao.com/assets/img/about/logos/channel/friendadd_small_yellow_rect.png"
                alt="naver button"
              />
            </a>
            <a href="http://pf.kakao.com/_tAUxlj/chat" target="_blank">
              <img
                className="social-button"
                src="https://developers.kakao.com/assets/img/about/logos/channel/consult_small_yellow_pc.png"
                alt="naver button"
              />
            </a>  
            */}


          <IconContainer>
     
           
            <a href="https://blog.naver.com/wesop_co" target="_blank">
              <img
                className="social-button"
                src={naverBlogIcon}
                alt="naver button"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCrYoU6WS1xKAIucwLnR49kA"
              target="_blank"
            >
              <img
                className="social-button"
                src={youtubeIcon}
                alt="youtube button"
              />
            </a>
            <a
              href="http://facebook.com/%EC%83%B5%EC%86%94-2088055467916853/"
              target="_blank"
            >
              <img
                className="social-button"
                src={facebookIcon}
                alt="facebook button"
              />
            </a>
          </IconContainer>
        </Column>
        <Column>
          <Link
            href="http://service.shop-sol.com/"
            style={{ marginRight: '30px' }}
          >
            서비스 이용약관
          </Link>
          <Link href="http://common.shop-sol.com:8080/solinfo/cu">개인정보 처리방침</Link>
        </Column>
      </Container>
    </Wrapper>
  );
};
