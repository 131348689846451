import React from 'react';
import styled from 'styled-components';
import device from '../../Styles/Device';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.darkGreyColor};
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
  margin-top: -1px;
  @media ${device.desktop} {
    padding: 120px 0;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: ${props => props.theme.maxWidth};
  margin-left: 0%;
  margin-right: 10%;

  @media ${device.desktop} {
    & {
      margin-left: 10%;
    }
  }
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  flex: 1 1 0%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &:first-child {
    align-items: flex-start;
  }
  &:nth-child(2) {
    align-items: flex-end;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${device.tablet} {
    width: 12em;
  }

  @media ${device.phone} {
    width: 11.5em;
    font-size: 0.8em;
  }
`;
const UnderTextBoxContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 80px;
`;

const UnderTextBox = styled.div`
  position: absolute;
  top: 0;
  right: 96px;

  @media ${device.laptop} {
    right: 85px;
  }
  @media ${device.laptopLarge} {
    right: 96px;
  }
  @media ${device.tablet} {
    left: 10%;
    right: auto;
  }
  @media ${device.phone} {
    right: auto;
    left: 10%;
  }
`;

const H2 = styled.h2`
  @media ${device.phone} {
    font-size: 1.45em;
  }
  color: ${props => props.theme.textWhiteColor};
`;

const H3 = styled.h3`
  color: ${props => props.theme.textGrayColor};
  font-weight: 300;
  &:first-child {
    margin-top: 15%;
  }
  @media ${device.desktop} {
    &:first-child {
      margin-top: 10%;
    }
  }
`;
const Border = styled.div`
  width: 100%;
  border: 0.5px solid #7a7b80;
`;

export default () => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Column>
            <Border />
          </Column>
          <Column>
            <TextBox>
              <H2>출퇴근관리, 자동급여계산, 업무관리를</H2>
              <H2>한번에 해결할 수 있습니다.</H2>
            </TextBox>
          </Column>
        </Row>
        <Row>
          <UnderTextBoxContainer>
            <UnderTextBox>
              <H3>직원관리에 시간을 낭비하지 마세요.</H3>
              <H3>복잡한 시스템에 어려워하지 마세요.</H3>
              <H3>매출 증대에만 집중하세요.</H3>
            </UnderTextBox>
          </UnderTextBoxContainer>
        </Row>
      </Container>
    </Wrapper>
  );
};
