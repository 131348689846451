import youngManImg from '../images/youngman.png';
import jinoImg from '../images/jino.png';
import yunaImg from '../images/yuna.png';
import kiyunImg from '../images/kiyun.png';

export const shopData = [
  {
    avatarImg: youngManImg,
    name: '원영만',
    shopName: '헝그리덩키',
    text: `습관적으로 5분씩 지각을 하던 직원들이
    샵솔을 사용한 이후부터 매장에 일찍 도착해서 준비하기 시작했어요.
    지각으로 스트레스 받는 분들에게 추천드리고 싶어요.`,
  },
  {
    avatarImg: jinoImg,
    name: '전진오',
    shopName: '큐브PC방 역촌점',
    text: `제가 매장에 없을 때도 샵솔 하나로
    직원들이 출근을 했는지, 지각을 했는지 바로 알 수 있어서 편리해요.
    여러 매장을 관리할 때 유용합니다.`,
  },
  {
    avatarImg: yunaImg,
    name: '장윤아',
    shopName: '로즈핀치',
    text: `정확하게 급여가 계산되서 놀랐어요.
    월급날 정신이 없었는데 샵솔을 쓰고나서 급여계산 걱정이 없어졌어요.
    세무사에게도 깔끔하게 작성된 샵솔의 엑셀파일을 드리니 좋아하셨어요. 
    `,
  },
  {
    avatarImg: kiyunImg,
    name: '김기윤',
    shopName: '런치포유',
    text: `샵솔의 체크리스트 서비스를 사용한 뒤로
    마감조가 에어컨을 켜놓고 퇴근하는 일이 없어졌어요.
    혹시나 했던 불안감들이 앱으로 확인이 가능하니 마음이 편해졌어요 
    `,
  },
];
