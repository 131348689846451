import styled from 'styled-components';
import device from '../../Styles/Device';

export const ColumnContainer = styled.div`
  display: flex;
  max-width: ${props => props.theme.maxWidth};
  width: 100%;
  flex-direction: column;

  padding: 80px 0px;

  margin-left: 10%;
  margin-right: 10%;

  @media ${device.desktop} {
    flex-direction: row;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &:after {
      content: '';
      display: block;
      min-height: inherit;
      font-size: 0;
    }
  }
`;

export const ColumnReverseContainer = styled.div`
  max-width: ${props => props.theme.maxWidth};
  width: 100%;

  display: flex;
  flex-direction: column-reverse;

  min-height: 550px;

  margin-left: 10%;
  margin-right: 10%;

  @media ${device.tablet} {
    min-height: 700px;
  }
  @media ${device.laptop} {
    min-height: 700px;
  }
  @media ${device.desktop} {
    flex-direction: row;
    min-height: 700px;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &:after {
      content: '';
      display: block;
      min-height: inherit;
      font-size: 0;
    }
  }
`;
