import React from 'react';
import styled from 'styled-components';
import { ShopCalendarImg } from '../Images';
import device from '../../Styles/Device';
import { ColumnContainer } from './styles';

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Column = styled.div`
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.desktop} {
    &:first-child {
      justify-content: center;
      align-items: flex-start;
    }
    &:last-child {
      justify-content: center;
      align-items: flex-end;
    }
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.desktop} {
    align-items: flex-start;
  }
  @media ${device.phone} {
    margin-top: 50px;
  }
  @media ${device.tablet} {
    margin-top: 50px;
    text-align: center;
  }
  @media ${device.laptop} {
    margin-top: 50px;
  }
`;

const H1 = styled.h1`
  color: ${props => props.theme.textBlackColor};
  font-weight: 400;
  font-size: 2.5em;
  line-height: 1.2;
  margin: 10px 0;
  text-align: center;
  @media ${device.laptopLarge} {
    margin: 10px 0;
    text-align: start;
  }
  @media ${device.desktop} {
    margin: 10px 0;
    text-align: start;
  }
  padding-right: 2px;
`;

const H4 = styled.h4`
  color: ${props => props.theme.textBlueColor};
  margin-top: 10px;
  font-weight: 300;
  line-height: 1.5;
  font-size: 1.2em;
  margin-left: 4px;
  &:first-child {
    color: ${props => props.theme.textRedColor};
    font-weight: 400;
  }
  text-align: center;

  @media ${device.phone} {
    margin-top: 4px;
    text-align: center;
  }
  @media ${device.laptopLarge} {
    text-align: start;
  }
  @media ${device.desktop} {
    text-align: start;
  }
`;

export default () => {
  return (
    <Wrapper>
      <ColumnContainer>
        <Column>
          <TextBox>
            <H4>샵솔로 한번에</H4>
            <H1>
              <p>복잡한 절차없이</p>
              <p>손쉬운 일정관리</p>
            </H1>
            <H4>
              바쁜 업무속에 복잡했던 일정변경을 <br />
              클릭 한번으로 해결할 수 있습니다.
            </H4>
          </TextBox>
        </Column>
        <Column>
          <ShopCalendarImg />
        </Column>
      </ColumnContainer>
    </Wrapper>
  );
};
