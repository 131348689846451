import React from 'react';
import styled from 'styled-components';
import device from '../../Styles/Device';
import boxImg1 from '../../images/boxImg1.png';
import boxImg2 from '../../images/boxImg2.png';
import boxImg3 from '../../images/boxImg3.png';
import androidIconImg from '../../images/androidstore.png';
import appleIconImg from '../../images/apple-store.png';
import { DownloadIcon } from '../Icon';

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  background-color: white;
  padding: 80px 0;
  justify-content: center;
  align-items: center;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &:after {
      content: '';
      display: block;
      min-height: inherit;
      font-size: 0;
    }
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.phone} {
    margin-top: 50px;
  }
  @media ${device.tablet} {
    margin-top: 50px;
  }
`;

const TitleContainer = styled.div`
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.phone} {
    flex-direction: column;
  }
`;
const Button = styled.div`
  font-size: 1.1em;
  font-weight: 500;
  color: ${props => props.theme.textBlackColor};
  background: none;
  width: 12.5em;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border: 0.5px solid #d9d9d9;
  cursor: pointer;
  margin: 30px 20px;
  @media ${device.phone} {
    margin: 30px 0 0 0;
    &:last-child {
      margin: 10px 0 10px 0;
    }
  }
  @media ${device.tablet} {
    margin: 30px 10px;
  }
`;
const Box = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
  @media ${device.tablet} {
    flex-direction: column;
  }
  @media ${device.phone} {
    flex-direction: column;
  }
`;

const BoxItem = styled.div`
  width: 100%;
  max-width: 400px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  align-items: center h3 {
    color: ${props => props.theme.blackColor};
    padding: 10px 50px 0px 50px;
  }

  span {
    color: ${props => props.theme.textBlueColor};
  }

  @media ${device.desktop} {
    margin-top: 30px;
  }

  & h3 {
    margin-top: 15px;
  }
`;

const H1 = styled.h1`
  color: ${props => props.theme.textBlackColor};
  font-weight: 400;
  font-size: 2.5em;
  @media ${device.phone} {
    font-size: 2.2em;
  }
`;

const H3 = styled.h3`
  color: ${props => props.theme.textBlueColor};
  font-weight: 300;
  margin-top: 10px;
`;
const H4 = styled.h4`
  color: ${props => props.theme.textBlueColor};
  font-weight: 300;
  padding: 10px 50px 0px 50px;
  font-size: 1.2em;
  @media ${device.tablet} {
    margin-bottom: 40px;
  }
  @media ${device.phone} {
    margin-bottom: 40px;
  }
`;

const Image = styled.img`
  width: 75%;
  margin: 0 auto;
  /* how to fix explorer height: auto */
  min-height: 1px;
`;

const BoxData = [
  {
    imgSrc: boxImg1,
    title: '출퇴근관리',
    text: `지각, 조퇴, 휴무관리부터 
      자율출퇴근, 재택근무까지 
      스마트폰 하나로 해결`,
  },
  {
    imgSrc: boxImg2,
    title: '급여관리',
    text: `각종 수당, 4대 보험부터 
    프리랜서 설정까지 
    급여계산을 자동으로 해결`,
  },
  {
    imgSrc: boxImg3,
    title: '업무관리',
    text: `직원의 체크리스트로
    놓치는 업무 없이 
    하루일과를 깔끔하게 해결`,
  },
];
export default () => {
  return (
    <Wrapper>
      <Container>
        {/* <TitleContainer>
          <H1>
            샵솔은 이 모든 서비스가 <span style={{ color: '#FE5353' }}>무료!</span>
          </H1>
          <H3>
            19,900원의 서비스를 무료로 사용하세요. <br />
            샵솔은 모든 사장님을 응원합니다.
          </H3>
          <H3 style={{fontSize : '12.5px', margin : '5px 0 15px 0', color : 'rgb(171, 171, 171)'}}>
            ( 관리자모드(PC)는 유료화로 전활될 예정입니다. )
          </H3>
        </TitleContainer> */}
        <Box>
          {BoxData.map((data, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <BoxItem key={index}>
                <Image src={data.imgSrc} alt="" />
                <h3>{data.title}</h3>
                <H4>
                  {data.text.split('\n').map(line => {
                    return (
                      <span>
                        {line}
                        <br />
                      </span>
                    );
                  })}
                </H4>
              </BoxItem>
            );
          })}
        </Box>
        <ButtonContainer>
          <a href="https://play.google.com/store/apps/details?id=com.wesop.appshopsol">
            <Button>
              <DownloadIcon src={androidIconImg} />
              <div>구글 플레이 다운로드</div>
            </Button>
          </a>
          <a href="https://itunes.apple.com/kr/app/샵솔/id1408364175?l=ko&ls=1&mt=8">
            <Button>
              <DownloadIcon src={appleIconImg} />
              <span>앱스토어 다운로드</span>
            </Button>
          </a>
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
};
