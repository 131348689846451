import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import LogoImage from '../images/header-logo.svg';
import FormItem from './FormItem';
import Checkbox from './Checkbox';
import blurImage from '../images/shopsol_login_page_background.png';
import device from '../Styles/Device';

const Wrap = styled.div`
  width: 100%;
  height: 100%;
`;

const Title = styled.h1`
  margin-top: 96px;
  margin-bottom: 51px;
  font-size: 2em;
  color: #16181d;
`;

const Container = styled.div`
  top: 52px;
  display: flex;
  width: 100%;
  height: 100%;
`;

const HalfLeftContainer = styled.div`
  flex: 1 1 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const HalfRightContainer = styled.div`
  flex: 1 1 0;
  display: none;
  @media ${device.desktop} {
    display: flex;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 620px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 0 30px;
  @media ${device.tablet} {
    max-width: 580px;
  }
  @media ${device.laptop} {
    max-width: 720px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${blurImage}) no-repeat center center/cover;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LogoImgView = styled.img`
  margin-top: 20px;
  height: 64px;
  @media ${device.phone} {
    height: 44px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 25px;
`;

const SignInButton = styled.button`
  margin-top: 34px;
  font-size: 20px;
  font-weight: 400;
  display: inline-flex;
  width: 100%;
  height: 64px;
  outline: none;
  border: none;
  color: white;
  background: #d8d8d8;
  justify-content: center;
  cursor: pointer;
`;

const ActionGroup = styled.div`
  margin-top: 34px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Action = styled.a`
  font-size: 14px;
  color: #bfc0c1;
  & + & {
    margin-left: 13px;
  }
  @media ${device.desktop} {
    font-size: 16px;
    & + & {
      margin-left: 23px;
    }
  }
`;

const useInput = (initValue = null) => {
  const [value, setter] = useState(initValue);
  const handler = useCallback(e => {
    setter(e.target.value);
  }, []);
  return [value, handler];
};

export default () => {
  const [id, onChangeId] = useInput('');
  const [password, onChangePassword] = useInput('');
  const [isSaveId, onChangeSaveId] = useInput(false);
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
    },
    [id, password],
  );

  return (
    <Wrap>
      <Container>
        <HalfLeftContainer>
          <FormContainer>
            <form onSubmit={onSubmit}>
              <LogoImgView src={LogoImage} />
              <Title>로그인</Title>
              <FormItem
                type="text"
                placeholder="아이디"
                value={id}
                onChange={onChangeId}
                required
              />
              <FormItem
                type="password"
                placeholder="비밀번호"
                value={password}
                onChange={onChangePassword}
                required
              />
              <CheckboxContainer>
                <StyledCheckbox
                  title="아이디 저장"
                  value={isSaveId}
                  onChange={onChangeSaveId}
                />
              </CheckboxContainer>
              <SignInButton>로 그 인</SignInButton>
            </form>
            <ActionGroup>
              <Action>회원가입</Action>
              <Action>아이디 찾기</Action>
              <Action>비밀번호 찾기</Action>
            </ActionGroup>
          </FormContainer>
        </HalfLeftContainer>
        <HalfRightContainer>
          <ImageContainer />
        </HalfRightContainer>
      </Container>
    </Wrap>
  );
};
