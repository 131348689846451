import React from 'react';
import styled from 'styled-components';
import device from '../Styles/Device';
import LogoImage from '../images/header-logo.svg';
import Person from '../images/person.png';
import CUlogo from '../images/CUlogo.png';
import header_logo from '../images/header_logo.png';
import phoneLogin from '../images/phoneLogin.png';
import shopStatus from '../images/shopStatus.png';
import shopPay from '../images/shopPay.png';
import shopCalendar from '../images/shopCalendar.png';

const PersonImg = styled.img`
  margin-top: 50px;
  width: 100%;
  height: auto;
  /* how to fix explorer height auto issue */
  min-height: 1px;
`;

const PhoneLoginImage = styled.img`
  width: 450px;
  @media ${device.phone} {
    width: 350px;
  }
`;

const ShopImg = styled.img`
  width: 360px;
  @media ${device.tablet} {
    width: 320px;
  }
  @media ${device.phone} {
    width: 300px;
  }
`;

const LogoImgView = styled.img`
  height: 64px;
  @media ${device.phone} {
    height: 38px;
  }
`;

export const Logo = () => <LogoImgView src={header_logo} />;

export const Column2Img = () => <PersonImg src={CUlogo} alt="" />;

export const PhoneLoginImg = () => <PhoneLoginImage src={phoneLogin} alt="" />;

export const ShopStatusImg = () => <ShopImg src={shopStatus} alt="" />;

export const ShopPayImg = () => <ShopImg src={shopPay} alt="" />;

export const ShopCalendarImg = () => <ShopImg src={shopCalendar} alt="" />;
