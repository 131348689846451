import React from 'react';
import styled from 'styled-components';
import { PhoneLoginImg } from '../Images';
import device from '../../Styles/Device';
import { ColumnReverseContainer } from './styles';

const Wrapper = styled.div`
  background-color: white;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Column = styled.div`
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.desktop} {
    &:first-child {
      justify-content: center;
      align-items: flex-start;
    }
    &:last-child {
      justify-content: center;
      align-items: flex-end;
    }
  }
`;

const TextBox = styled.div`
  @media ${device.tablet} {
    text-align: center;
  }
`;

const H1 = styled.h1`
  color: ${props => props.theme.textBlackColor};
  font-weight: 400;
  margin-bottom: 20px;
  padding-right: 2px;
  font-size: 2.8em;
  @media ${device.phone} {
    margin-top: 4px;
  }
`;

const H4 = styled.h4`
  color: ${props => props.theme.textBlueColor};
  margin-top: 10px;
  line-height: 1.5;
  @media ${device.phone} {
    margin-top: 4px;
    text-align: center;
  }
  margin-left: 4px;
`;

export default () => {
  return (
    <Wrapper>
      <ColumnReverseContainer>
        <Column>
          <PhoneLoginImg />
        </Column>
        <Column>
          <TextBox>
            <H1>샵솔(Shopsol)은?</H1>
            <H4>
              세무사와 노무전문가가 <br />
              직원관리에 어려움을 겪고 있는 자영업자를 위해 <br />
              실무경험을 바탕으로 핵심만 담은 서비스입니다. <br />
              직원관리 시간을 1/3로 줄일 수 있습니다.
            </H4>
          </TextBox>
        </Column>
      </ColumnReverseContainer>
    </Wrapper>
  );
};
