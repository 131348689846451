import React from 'react';
import styled from 'styled-components';
import device from '../../Styles/Device';
import blurImage from '../../images/blur-image.jpg';
import blurMobileImage from '../../images/blur-mobile-image.png';
import blurTabletImage from '../../images/blur-tablet-image.png';

const Wrapper = styled.div`
  background: url(${blurImage}) no-repeat center center/cover;
  min-height: 350px;
  @media ${device.tablet} {
    background: url(${blurTabletImage}) no-repeat center center/cover;
  }
  @media ${device.laptop} {
    background: url(${blurTabletImage}) no-repeat center center/cover;
  }
  @media ${device.phone} {
    background: url(${blurMobileImage}) no-repeat center center/cover;
    min-height: 500px;
  }
`;

const Container = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  @media ${device.desktop} {
    flex-direction: row;
  }
`;
const H1 = styled.h1`
  color: white;
  margin-left: 10%;
  padding-top: 40px;
  margin-bottom: 20px;
`;
const H3 = styled.h3`
  color: white;
  font-weight: 300;
`;

export default () => {
  return (
    <Wrapper>
      <Container>
        <H1>지금, 바로 시작하세요.</H1>
        <TextContainer>
          <H3>당연히 누려야 할 편리성을 이제 경험해보세요. &nbsp;</H3>
        </TextContainer>
        <TextContainer>
          <H3>이제 불필요한 업무는 1/3로 줄이고, 매출 증대에만 신경쓰세요.</H3>
        </TextContainer>
      </Container>
    </Wrapper>
  );
};
