import React from 'react';
import First from './Column/First';
import Column3 from './Column/Column3';
import Column4 from './Column/Column4';
import Column5 from './Column/Column5';
import Column6 from './Column/Column6';
import Column7 from './Column/Column7';
import Column8 from './Column/Column8';
import Column9 from './Column/Column9';
import Promotion from './Column/Promotion';
import Carousel from './Carousel';
import Column10 from './Column/Column10';
import Footer from './Footer';
import Header from './Header';
import NoticePopUp from './Column/NoticePopUp'

export default () => {
  return (
    <>
      <Header />
      <First />
      <Column3 />
      <Column4 />
      <Footer />
    </>
  );
};
