import React from 'react';
import styled from 'styled-components';
import device from '../Styles/Device';
import { Logo } from './Images';

const Header = styled.header`
  width: 100%;
  border: 0;
  position: fixed;
  top: 0;
  background-color: white;
  border-bottom: ${props => props.theme.boxBorder};
  border-radius: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0px;
  z-index: 2;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${props => props.theme.maxWidth};

  display: flex;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
  @media ${device.phone} {
    margin-left: 5%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: 0%;
  margin-right: 0%;
`;

const HeaderColumn = styled.div`
  align-items: center;

  display: flex;

  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    width: 100%;
    justify-content: flex-end;
  }

  @media ${device.phone} {
    &:last-child {
      padding-right: 1%;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  @media ${device.phone} {
    margin-left: 8px;
  }

  & a {
    display: block;
  }

  & a + a {
    margin-left: 20px;
  }

  @media ${device.tablet} {
    & a + a {
      margin: 15px;
    }
  }
  @media ${device.phone} {
    & a + a {
      margin-left: 10px;
    }
  }
`;

const Button = styled.button`
  word-wrap: nowrap;
  height: 35px;
  background-color: ${props => props.theme.buttonColor};
  color: white;
  cursor: pointer;
  border: 0;

  &:hover {
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
      0 0 20px rgba(255, 255, 255, 0.2);
    outline-offset: 15px;
  }
  &:focus {
    outline: none;
  }
  width: 140px;
  @media ${device.phone} {
    font-size: 0.8em;
    height: 28px;

    width: 100px;
    & + & {
      margin-right: 4px;
    }
  }
`;

export default () => {
  return (
    <Header>
      <HeaderWrapper>
        <Wrapper>
          <HeaderColumn>
            <a href="#">
              <Logo />
            </a>
          </HeaderColumn>
          <HeaderColumn>
            <ButtonGroup>
              <a href="http://ceo.custaffhr.com/">
                <Button id="supervisor">관리자모드(점주)</Button>
              </a>
            </ButtonGroup>
          </HeaderColumn>
        </Wrapper>
      </HeaderWrapper>
    </Header>
  );
};
