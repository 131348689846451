import styled from 'styled-components';
import device from '../Styles/Device';

export const DownloadIcon = styled.img`
  margin-right: 10px;
  width: 20px;
  height: 22px;
  @media ${device.phone} {
    width: 15px;
    height: 16.3px;
  }
`;
