import React from 'react';
import styled from 'styled-components';
import device from '../Styles/Device';

const Styled = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  & span {
    color: #727781;
    font-size: 14px;
  }

  @media ${device.desktop} {
    font-size: 20px;
    font-weight: 400;
  }
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: 18px;
  height: 18px;
  vertical-align: center;
  outline: none;
  border: none;
  color: #ffffff;
  background-color: ${props =>
    props.value ? '#FE5353 !important' : '#FFFFFF !important'};
  background: #ffffff;
  &[type='checkbox']:checked {
    background-color: #a77e2d !important;
    color: #ffffff !important;
  }
`;

export default ({ className, title, children, ...rest }) => {
  return (
    <Styled className={className}>
      {/* eslint-disable-next-line */}
      <StyledCheckbox {...rest} />
      <span>{title}</span>
    </Styled>
  );
};
