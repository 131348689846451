import React, { Component } from 'react';
import styled from 'styled-components';
import device from '../../Styles/Device';
import PopUpImage from '../../images/homeWorking_popup.png';

class NoticePopUp extends Component {

  state = {
    isModalOpen: true
  }

  modalClose = () => {
    this.setState({ isModalOpen: false })
  }

  render() {

    const NoticePopUpArea = styled.div`
      position : fixed;
      top : 50%;
      left : 30%;
      width: 30%;
      background-color : #fff;
      transform: translate(-50%, -50%);
      z-index : 3;
      box-shadow : 2px 2px 5px 0px #757575;
      @media ${device.laptopLarge} {
        width: 35%;
        left : 30%;
      }
      @media ${device.tablet} {
        width: 50%;
        left : 50%;
      }
      @media ${device.phone} {
        width: 80%;
        left : 50%;
      }
    `;
    const PopUpClose = styled.div`
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      padding : 5px;
      text-align : center;
      font-size : 14px;
      background-color: #fff;
      cursor: pointer;
      box-sizing: content-box;
      & :hover {
        box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
          0 0 20px rgba(255, 255, 255, 0.2);
        outline-offset: 15px;
      }
    `;
    const Image = styled.img`
      width: 100%;
    `;
    const PopUpBg = styled.div`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #000;
      opacity: 0.5;
    `;
    const BackGround = styled.div`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
    `;

    const { isModalOpen } = this.state;
    
    return (
      <div>
        { isModalOpen ?
          <BackGround>
            <NoticePopUpArea>
              <PopUpClose onClick={this.modalClose}>X</PopUpClose>
              <Image src={PopUpImage} />
            </NoticePopUpArea>
            <PopUpBg onClick={this.modalClose}></PopUpBg>
          </BackGround>
        : null}
      </div>
    );
  }
}

export default NoticePopUp;