import React from 'react';
import styled from 'styled-components';
import device from '../../Styles/Device';
import { FiCheck } from "react-icons/fi";


const Wrapper = styled.div`
    width: 100%;

    display: flex;
    padding: 80px 0;
    justify-content: center;
    align-items: center;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        &:after {
            content: '';
            display: block;
            min-height: inherit;
            font-size: 0;
        }
    }
`;
const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media ${device.phone} {

    }
    @media ${device.tablet} {

    }
`;

// mobileS: '320px',
// mobileM: '375px',
// mobileL: '550px',
// tablet: '768px',
// laptop: '1024px',
// laptopL: '1440px',
// desktop: '2560px',
const BoxArea = styled.ul`
    display: flex;
    width: 80%;
    max-width: 1200px;
    flex-direction: row;
    justify-content: space-between;
    @media ${device.laptopL} {
        width: 90%;
    }
    @media ${device.laptop} {
        width: 100%;
    }
    @media ${device.tablet} {
        flex-direction: column;
    }
    @media ${device.phone} {
        flex-direction: column;
    }
`;
const Box = styled.li`
    display: flex;
    flex-direction: column;
    margin: 3%;
    padding: 1.5%;
    width: 33.33333%;
    position: relative;
    border: 1px solid #ddd;
    min-height: 470px;
    background-color: white;
    @media ${device.tablet} {
        width: 45%;
        margin: 0 auto 50px;
        min-height: 400px;
    }
    @media ${device.phone} {
        width: 50%;
        margin: 0 auto 30px;
        min-height: 360px;
        & li {
            margin-top: 30px;
        }
    }

    span {
        color: ${props => props.theme.textBlueColor};
    }

    /* max-width: 400px;
    margin-top: 0;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px; */
`;
const PromotionType = styled.div`
    padding: 10px 0;
    background-color: black;
    text-align: center;
`;
const PromotionCnt = styled.div`
    margin: 10px 0;
    padding: 10% 20px;
    min-height: 300px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    @media ${device.tablet} {
        min-height: 260px;
    }
    @media ${device.phone} {
        min-height: 220px;
    }
`;
const CntTitle = styled.div`
    font-size: 1.2em;
    font-weight: '700';
    text-align: center;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #ddd;
`;
const ServiceBox = styled.div`
    margin-top: 10px;
    padding: 6px 0;
    font-size: 0.8em;
    text-align: center;
    background-color: #ddd;
`;
const ServiceList = styled.div`
    justify-items: flex-start;
    align-self: flex-start;
    margin-top: 10px;
    font-size: 0.8em;
`;
const PromotionBottom = styled.div`
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    padding: 3% 0;
    font-size: 0.9em;
    text-align: center;
    border: 1px solid #000;
    border-radius: 20px;
    @media ${device.laptop} {
        padding: 4% 0;
    }
`;

const InfoArea = styled.div`
    margin-top: 50px;
    padding: 20px;
    width: 80%;
    max-width: 1120px;
    text-align: center;
    border: 1px solid #000;
    background-color: white;
    & div {
        flex: 1;
        width: 33.3333%;
        float: left;
        text-align: center;
    }
    & p {
        font-size: 0.9em
    }
    & p.line {
        text-decoration: line-through;
    }
    @media ${device.tablet} {
        margin-top: 20px;
    }
    @media ${device.phone} {
        margin-top: 20px;
    }
`;

export default () => {
  return (
    <Wrapper>
        <Container>
            <BoxArea>
                <Box>
                    <PromotionType>
                        <strong style={{color: 'white',}}>샵솔 베이직</strong>
                    </PromotionType>
                    <PromotionCnt>
                        <CntTitle>모바일 앱</CntTitle>
                        <ServiceList>
                            <FiCheck style={{ marginRight: 3, color: '#FE5353', fontSize: 14, verticalAlign: 'middle', }}/>
                            <span style={{color: '#000'}}>스마트출퇴근</span>
                        </ServiceList>
                        <ServiceList>
                            <FiCheck style={{ marginRight: 3, color: '#FE5353', fontSize: 14, verticalAlign: 'middle', }}/>
                            <span style={{color: '#000'}}>자동급여계산</span>
                        </ServiceList>
                        <ServiceList>
                            <FiCheck style={{ marginRight: 3, color: '#FE5353', fontSize: 14, verticalAlign: 'middle', }}/>
                            <span style={{color: '#000'}}>체크리스트(업무관리)</span>
                        </ServiceList>
                        <ServiceList>
                            <FiCheck style={{ marginRight: 3, color: '#FE5353', fontSize: 14, verticalAlign: 'middle', }}/>
                            <span style={{color: '#000'}}>QR키트</span>
                        </ServiceList>
                    </PromotionCnt>
                    <PromotionBottom>모든 사업장 무료</PromotionBottom>
                </Box>
                <Box>
                    <PromotionType>
                        <strong style={{color: 'white',}}>샵솔 비즈니스</strong>
                    </PromotionType>
                    <PromotionCnt>
                        <CntTitle>모바일 앱 + 관리자PC</CntTitle>
                        <ServiceBox>모바일 앱 서비스</ServiceBox>
                        <i style={{ color: '#ddd', fontSize: 20, textAlign: 'center', display: 'block', lineHeight: 1, marginTop: '10px', }}>+</i>
                        <ServiceList>
                            <FiCheck style={{ marginRight: 3, color: '#FE5353', fontSize: 14, verticalAlign: 'middle', }}/>
                            <span style={{color: '#000'}}>PC통합관리</span>
                        </ServiceList>
                        <ServiceList>
                            <FiCheck style={{ marginRight: 3, color: '#FE5353', fontSize: 14, verticalAlign: 'middle', }}/>
                            <span style={{color: '#000'}}>출퇴근기록/급여 EXCEL 다운로드</span>
                        </ServiceList>
                        <ServiceList>
                            <FiCheck style={{ marginRight: 3, color: '#FE5353', fontSize: 14, verticalAlign: 'middle', }}/>
                            <span style={{color: '#000'}}>4대보험 EDI(6월 오픈예정)</span>
                        </ServiceList>
                    </PromotionCnt>
                    <PromotionBottom>사업장 당 19,800원/월</PromotionBottom>
                </Box>
                <Box>
                    <PromotionType>
                        <strong style={{color: 'white',}}>샵솔 프리미엄</strong>
                    </PromotionType>
                    <PromotionCnt>
                        <CntTitle>프랜차이즈 특화 서비스</CntTitle>
                        <ServiceBox>모바일 앱 서비스</ServiceBox>
                        <ServiceBox>관리자 PC 서비스</ServiceBox>
                        <i style={{ color: '#ddd', fontSize: 20, textAlign: 'center', display: 'block', lineHeight: 1, marginTop: '10px', }}>+</i>
                        <ServiceList>
                            <FiCheck style={{ marginRight: 3, color: '#FE5353', fontSize: 14, verticalAlign: 'middle', }}/>
                            <span style={{color: '#000'}}>커스터마이징 앱(규모별 상이)</span>
                        </ServiceList>
                        <ServiceList>
                            <FiCheck style={{ marginRight: 3, color: '#FE5353', fontSize: 14, verticalAlign: 'middle', }}/>
                            <span style={{color: '#000'}}>(옵션) 조기경보시스템, 본사 월간지 등</span>
                        </ServiceList>
                    </PromotionCnt>
                    <PromotionBottom>문의하기</PromotionBottom>
                </Box>
            </BoxArea>
            <InfoArea>
                <div>
                    <p>프로모션</p>
                    <p>샵솔 비즈니스 3개월 이용권</p>
                </div>
                <div>
                    <p className="line">59,400원</p>
                    <p>18,000원(약 70% 할인)</p>
                </div>
                <div>
                    <p>대상 : 4월 가입 사업장</p>
                </div>
            </InfoArea>
        </Container>
    </Wrapper>
  );
};
